import {browserHistory, Route, Router} from 'react-router';
import React, {lazy, Suspense} from "react";
import Loader from "../components/Loader";
import {Provider} from "react-redux";
import store from "../common/utilities/storage/store";
import {Dimensions} from 'react-native';
import currentDimensions from '../common/actions/updateDimensions';
import {PersistGate} from 'redux-persist/integration/react';
import {persistStore} from 'redux-persist';
import cogoToast from 'cogo-toast';
import InviteDetails from "./inviteDetails";
import {getLanguage, setLanguage} from "../common/utilities/i18n/utils";

const Badge = lazy(() => import('../screens/Badge/index'));
const WorkflowScreen = lazy(() => import('../veris-workflows/index'));
const WorkflowType = lazy(() => import('../veris-workflows/WorkflowType'));
const ActivityCompleted = lazy(() => import('../webCheckIn/ActivityCompleted/index'));

class App extends React.Component {
    constructor(params, context) {
        super(params, context);
        this.state = {};
        this.persistor = persistStore(store)
    }

    _getFilteredDimensions = (currentHeight, currentWidth) => {
        if (!currentHeight || !currentWidth) {
            let {height, width} = Dimensions.get('window');
            currentHeight = height;
            currentWidth = width;
        }
        if (currentWidth > 768) {
            currentWidth = 550
        }
        if (currentHeight > 1024) {
            currentHeight = 1024
        }
        return [currentHeight, currentWidth];
    }

    _handleWindowResize = () => {
        let {height, width} = Dimensions.get('window');
        this._getFilteredDimensions(height, width);
        let [currentHeight, currentWidth] = this._getFilteredDimensions();
        store.dispatch(currentDimensions(currentHeight, currentWidth));
    }

    componentDidMount() {
        let locale = getLanguage();
        setLanguage(locale);
        window.addEventListener('resize', this._handleWindowResize);
        window.addEventListener('online', this.updateStatus)
        window.addEventListener('offline', this.updateStatus)
    }


    updateStatus(event) {
        if (navigator.onLine) {
            cogoToast.success('You are back online!');
        } else {
            cogoToast.error('You are offline, please check you internet connection!');
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._handleWindowResize);
        window.removeEventListener('online', this.updateStatus)
        window.removeEventListener('offline', this.updateStatus)

    }


    render() {
        let [height, width] = this._getFilteredDimensions();
        this._handleWindowResize();
        return (
            <Provider store={store}>
                <PersistGate loading={<div>Loading...</div>} persistor={this.persistor}>
                    <Suspense fallback={<Loader/>}>
                        <Router history={browserHistory} key={Math.random()}>
                            <Route path="/" component={InviteDetails}
                                   details={this.state}
                                   height={height}
                                   width={width}/>
                            <Route path="/workflowType" component={WorkflowType}
                                   height={height}
                                   width={width}/>
                            <Route path="/workflow/page/:page_no" component={WorkflowScreen}
                                   height={height}
                                   width={width}/>
                            <Route path="/summary" component={ActivityCompleted}
                                   height={height}
                                   width={width}/>
                            <Route path="/badge" component={Badge}
                                   height={height}
                                   width={width}/>
                        </Router>
                    </Suspense>
                </PersistGate>
            </Provider>
        )
    }
}

export default (App)
